import React, { useEffect, useState } from "react";
import axios from "axios";
import { shallow } from "zustand/shallow";
import { Box, Button, Flex, Card, Text, NumberFormatter } from "@mantine/core";
import { motion, AnimatePresence } from "framer-motion";

import {
  StyledImage,
  StyledPlaceholder,
  listVariants,
  itemVariants,
} from "./UserHistory";
import useAppStore from "./ride-challenge-store";

export const viewValues = {
  locations: "locations",
  keywords: "keywords",
  readings: "readings",
};

export const views = [
  { text: "Readings", value: viewValues.readings, context: "readings" },
  { text: "Keywords", value: viewValues.keywords, context: "keywords" },
  { text: "Locations", value: viewValues.locations, context: "locations" },
];

export default function DashboardHistory({ fetchData }) {
  const {
    appLoading,
    coords,
    location,
    effortId,
    entityInfo,
    setAppLoading,
    user,
  } = useAppStore(
    (state) => ({
      appLoading: state.appLoading,
      coords: state.coords,
      effortId: state.effortId,
      entityInfo: state.entityInfo,
      location: state.location,
      user: state.user,
      setAppLoading: state.setAppLoading,
    }),
    shallow
  );
  const [activeView, setActiveView] = useState(viewValues.readings);
  const [keywords, setKeywords] = useState([]);
  const [locations, setLocations] = useState([]);
  const [readings, setReadings] = useState([]);

  const activeViewInfo = views.find((v) => v.value === activeView);

  useEffect(() => {
    setAppLoading(true);
    setTimeout(() => {
      fetchHistory();
    }, 500);
  }, [activeView]);

  function fetchHistory() {
    const req = {
      ...coords,
      campaign_effort_id: effortId,
      location_id: location?.id || entityInfo.location?.id,
      user_id: user.id,
      context: activeViewInfo.context,
    };

    setAppLoading(true);

    axios
      .post(`/ride-challenge/retrieve-history/`, req)
      .then(({ data }) => {
        if (activeView === viewValues.keywords) {
          setKeywords(
            data.response.sort(
              (a, b) => new Date(b.claimed_at) - new Date(a.claimed_at)
            )
          );
        } else if (activeView === viewValues.locations) {
          setLocations(data.response);
        } else if (activeView === viewValues.readings) {
          setReadings(
            data.response.sort(
              (a, b) => new Date(b.verified_at) - new Date(a.verified_at)
            )
          );
        }
      })
      .then(() => {
        setTimeout(() => {
          setAppLoading(false);
        }, 500);
      })
      .catch((err) => {
        setKeywords([]);
        setLocations([]);
        setReadings([]);
        setAppLoading(false);
      });
  }

  return (
    <div style={{ minHeight: "200px" }}>
      <HistoryFilterButtons
        activeView={activeView}
        setActiveView={setActiveView}
      />
      <Box mt="lg">
        {activeView === viewValues.keywords && (
          <>
            {!appLoading && keywords.length == 0 && (
              <EmptyText text="You haven't entered any keywords yet. When you have, they will appear here." />
            )}
            {keywords.map((item) => (
              <motion.div
                key={item.id}
                variants={listVariants}
                initial="hidden"
                animate="visible"
              >
                <motion.div variants={itemVariants}>
                  <KeywordItem key={item.id} keyword={item} />
                </motion.div>
              </motion.div>
            ))}
          </>
        )}
        {activeView === viewValues.locations && (
          <>
            {!appLoading && locations.length == 0 && (
              <EmptyText text="You haven't visited any locations yet. When you have, they will appear here." />
            )}
            {locations.map((item) => (
              <motion.div
                key={item.id}
                variants={listVariants}
                initial="hidden"
                animate="visible"
              >
                <motion.div variants={itemVariants}>
                  <LocationItem key={item.id} location={item} />
                </motion.div>
              </motion.div>
            ))}
          </>
        )}
        {activeView === viewValues.readings && (
          <>
            {!appLoading && readings.length == 0 && (
              <EmptyText text="You don't have any verified odometer readings yet. When you do, they will appear here." />
            )}
            {readings.map((item) => (
              <motion.div
                key={item.id}
                variants={listVariants}
                initial="hidden"
                animate="visible"
              >
                <motion.div variants={itemVariants}>
                  <ReadingItem key={item.id} reading={item} />
                </motion.div>
              </motion.div>
            ))}
          </>
        )}
      </Box>
    </div>
  );
}

export const HistoryFilterButtons = ({ activeView, setActiveView }) => {
  return (
    <div>
      <Flex gap="xs" justify="center">
        {views.map((view, i) => (
          <Button
            key={i}
            onClick={() => setActiveView(view.value)}
            size="xs"
            variant={activeView === view.value ? "filled" : "light"}
            radius="lg"
          >
            {view.text}
          </Button>
        ))}
      </Flex>
    </div>
  );
};

export const KeywordItem = ({ keyword }) => {
  return (
    <CardContainer
      mb="md"
      style={{
        background: "var(--mantine-color-dark-9)",
        borderColor: "var(--mantine-color-dark-8)",
      }}
    >
      <div>
        <Flex align="start" gap="md">
          <div>
            {keyword.location_logo_url ? (
              <StyledImage
                height={50}
                width={50}
                src={keyword.location_logo_url}
              />
            ) : (
              <StyledPlaceholder height={50} width={50} />
            )}
          </div>
          <div style={{ flexGrow: 1 }}>
            <Text fw={600} c="white">
              {keyword.keyword_text}
            </Text>
            <Text c="dimmed" size="sm">
              {keyword.location_name}
            </Text>
          </div>
          <Text c="dimmed" size="sm">
            {new Date(keyword.claimed_at).toLocaleDateString()}
          </Text>
        </Flex>
      </div>
    </CardContainer>
  );
};

export const LocationItem = ({ location }) => {
  return (
    <CardContainer
      mb="md"
      style={{
        background: "var(--mantine-color-dark-9)",
        borderColor: "var(--mantine-color-dark-8)",
      }}
    >
      <div>
        <Flex gap="xs" align="center">
          <div>
            {location.logo_url ? (
              <StyledImage height={50} width={50} src={location.logo_url} />
            ) : (
              <StyledPlaceholder height={50} width={50} />
            )}
          </div>
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <Text fw={600} c="white">
              {location.name}
            </Text>
            <Text c="dimmed" size="sm">
              {location.city}, {location.state_province}
            </Text>
          </div>
        </Flex>
      </div>
    </CardContainer>
  );
};

export const ReadingItem = ({ reading }) => {
  const vehicleImage = reading?.user_vehicle?.assets[0]?.filename_url;

  return (
    <CardContainer>
      <Flex align="start" gap="md">
        <div>
          {vehicleImage ? (
            <StyledImage height={50} width={50} src={vehicleImage} />
          ) : (
            <StyledPlaceholder height={50} width={50} />
          )}
        </div>
        <div style={{ flexGrow: 1 }}>
          <Text fw={600} c="white">
            <NumberFormatter thousandSeparator value={reading.odometer_value} />{" "}
            mile{reading.odometer_value === 1 ? "" : "s"}
          </Text>
          <Text size="sm" c="dimmed">
            {reading.user_vehicle.year} {reading.user_vehicle.make}{" "}
            {reading.user_vehicle.model}
            {reading.user_vehicle.series
              ? ` ${reading.user_vehicle.series}`
              : ""}
          </Text>
          <Text size="sm" c="dimmed">
            {reading.location_name}
          </Text>
        </div>
        <Text c="dimmed" size="sm">
          {new Date(reading.verified_at).toLocaleDateString()}
        </Text>
      </Flex>
    </CardContainer>
  );
};

const CardContainer = ({ children }) => (
  <Card
    mb="sm"
    style={{
      background: "var(--mantine-color-dark-8)",
      borderColor: "var(--mantine-color-dark-6)",
    }}
  >
    {children}
  </Card>
);

const EmptyText = ({ text, altText }) => (
  <div
    style={{
      minHeight: "150px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Text
      style={{
        maxWidth: "275px",
      }}
      align="center"
      c="white"
      fw={600}
    >
      {text}
    </Text>
  </div>
);
