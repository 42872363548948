import React from "react";
import { shallow } from "zustand/shallow";
import styled from "styled-components";

import { OtherButton } from "./UserLocation";
import ViewTitle from "./ViewTitle";
import { LoyaltyMembership } from "@components/LoyaltyMembership";

import hogLogo from "@assets/images/hog-logo-color.png";

import useAppStore from "./ride-challenge-store";

export default function MembershipView() {
  const {
    effortId,
    location,
    membershipInfo,
    setActiveStep,
    setMembershipInfo,
    user,
    coords,
  } = useAppStore(
    (state) => ({
      coords: state.coords,
      effortId: state.effortId,
      location: state.location,
      membershipInfo: state.membershipInfo,
      setActiveStep: state.setActiveStep,
      setMembershipInfo: state.setMembershipInfo,
      user: state.user,
    }),
    shallow
  );

  return (
    <div>
      <StyledLogo src={hogLogo} />
      <ViewTitle
        title={"Membership"}
        subtitle={`Harley Owners Group and Passenger Members, add
your membership number here.`}
      />
      {user && (
        <LoyaltyMembership
          additionalReqData={{
            check: true,
            user_id: user.id,
            user_location_id: user.user_location_id,
            variety: 1,
            campaign_effort_id: effortId,
            dashboard: false,
          }}
          codeVerificationData={{
            ...coords,
            user_location_id: user?.user_location_id,
            campaign_effort_id: effortId,
            location_id: location?.id,
            user_id: user?.id,
          }}
          setMembershipInfo={(e) => {
            setMembershipInfo(e);
            setActiveStep("vehicle");
          }}
        />
      )}
      <OtherButton
        onClick={() => setActiveStep("vehicle")}
        text="Skip for now"
      />
    </div>
  );
}

const StyledLogo = styled.img`
  max-width: 300px;
  margin: 0 auto 1em auto;
  display: block;
`;
