import React, { useEffect, useState } from "react";
import axios from "axios";
import { shallow } from "zustand/shallow";
import { Flex, Box, Button } from "@mantine/core";

import UnverifiedItems from "./UnverifiedItems";
import useAppStore from "./ride-challenge-store";

export default function DashboardUnverified({ setView, children }) {
  const {
    coords,
    effortId,
    entityInfo,
    location,
    setAppLoading,
    user,
    verificationCode,
  } = useAppStore(
    (state) => ({
      coords: state.coords,
      effortId: state.effortId,
      entityInfo: state.entityInfo,
      location: state.location,
      setAppLoading: state.setAppLoading,
      user: state.user,
      verificationCode: state.verificationCode,
    }),
    shallow
  );
  const [unverifiedReadings, setUnverifiedReadings] = useState([]);

  useEffect(() => {
    fetchUnverified();
  }, []);

  function fetchUnverified() {
    const req = {
      ...coords,
      campaign_effort_id: effortId,
      location_id: entityInfo.location?.id,
      user_id: user.id,
    };

    setAppLoading(true);

    axios
      .post(`/ride-challenge/retrieve-unverified-readings/`, req)
      .then(({ data }) => {
        if (!data.response.length) {
          setAppLoading(false);
          setView();
        } else {
          setUnverifiedReadings(data.response);
          setAppLoading(false);
        }
      })
      .catch((err) => {
        setUnverifiedReadings([]);
        setAppLoading(false);
      });
  }

  return (
    <div>
      <UnverifiedItems
        additionalReqData={{
          ...coords,
          campaign_effort_id: effortId,
          location_id: entityInfo.location?.id,
        }}
        items={unverifiedReadings}
        // items={
        //   dashboardData &&
        //   dashboardData.unverified_data.map((m) => ({
        //     ...m,
        //     user_vehicle: foundEffort.user_vehicles.find(
        //       (f) => f.id === m.user_vehicle_id
        //     ),
        //   }))
        // }
        fetchData={() => {
          fetchUnverified();
        }}
        verificationCode={verificationCode}
        codeVerificationData={{
          ...coords,
          user_id: user?.id,
          user_location_id: user?.user_location_id,
          campaign_effort_id: effortId,
          location_id: location?.id,
        }}
      >
        {children}
      </UnverifiedItems>
    </div>
  );
}
