import React, { useEffect, useState } from "react";

const sizeProperties = {
  small: {
    radius: 145,
    fontSize: "12px",
  },
  base: {
    radius: 200,
    fontSize: "16px",
  },
};

export default function TierGauge({ sections = [], value = 0 }) {
  if (sections.length === 0) return null;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Use the appropriate size properties based on screen size
  const { radius, fontSize } = isMobile
    ? sizeProperties.small
    : sizeProperties.base;

  const strokeWidth = radius * (isMobile ? 0.16 : 0.19);

  // Normalize sections to fill the entire 0-100 range
  const normalizedSections = React.useMemo(() => {
    if (sections.length === 0) return [];

    // Get the total range of all sections
    const totalRange = sections.reduce((sum, section) => {
      const range = section.end - (section.start || 0);
      return sum + range;
    }, 0);

    let currentPosition = 0;

    // Map each section to a proportional part of 0-100
    return sections.map((section) => {
      const range = section.end - (section.start || 0);
      const proportionalRange = (range / totalRange) * 100;
      const normalizedSection = {
        ...section,
        start: currentPosition,
        end: currentPosition + proportionalRange,
      };
      currentPosition += proportionalRange;
      return normalizedSection;
    });
  }, [sections]);

  // Get the maximum value from the original sections
  const maxOriginalValue = Math.max(...sections.map((s) => s.end));

  // Normalize and clamp the input value
  const normalizedValue = value > 0 ? (value / maxOriginalValue) * 100 : 0;
  const clampedValue = Math.min(Math.max(normalizedValue, 0), 100);

  const [animatedValue, setAnimatedValue] = useState(clampedValue);

  const firstSectionColor = sections[0].color;
  const lastSectionColor = sections[sections.length - 1].color;

  useEffect(() => {
    const startValue = animatedValue;
    const endValue = clampedValue;
    const duration = 500;
    const startTime = performance.now();

    const animate = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);

      const easeOut = (t) => 1 - Math.pow(1 - t, 3);
      const easedProgress = easeOut(progress);

      const currentValue = startValue + (endValue - startValue) * easedProgress;
      setAnimatedValue(currentValue);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }, [clampedValue]);

  // Update these values to use the dynamic properties
  const innerRadius = radius - strokeWidth;
  const circumference = innerRadius * 2 * Math.PI;
  const arc = circumference * 0.75; // 270 degrees
  const transform = `rotate(69, ${radius}, ${radius})`;

  // Helper function to convert value to arc length with gap
  const valueToArcLength = (start, end) => {
    const range = Math.min(end, 100) - Math.min(start, 100);
    const gapSize = 2; // Size of gap in pixels
    return (range / 100) * arc - gapSize;
  };

  // Helper function to calculate label position
  const getLabelPosition = (startValue, endValue) => {
    const midValue = (startValue + endValue) / 2;
    // Adjust angle calculation to match the gauge rotation
    const angle = (midValue / 100) * 270 - 225; // 270 degrees total, starting at -135
    const angleRad = (angle * Math.PI) / 180;
    const labelRadius = radius * 1.15;

    return {
      x: radius + labelRadius * Math.cos(angleRad),
      y: radius + labelRadius * Math.sin(angleRad),
    };
  };

  // Helper function to add line break to long labels
  const formatLabel = (label) => {
    if (label.length <= 7) return label;

    // Find the space closest to the middle of the string
    const middle = Math.floor(label.length / 2);
    const firstSpace = label.lastIndexOf(" ", middle);
    const lastSpace = label.indexOf(" ", middle);

    // Choose the space closest to the middle
    const breakIndex =
      firstSpace !== -1 &&
      (lastSpace === -1 || middle - firstSpace <= lastSpace - middle)
        ? firstSpace
        : lastSpace;

    if (breakIndex === -1) return label;

    return [label.slice(0, breakIndex), label.slice(breakIndex + 1)];
  };

  // Helper function to calculate point position with clamped value
  const getPointPosition = (value) => {
    const clampedValue = Math.min(Math.max(value, 0), 100);
    const angle = ((clampedValue / 100) * 270 - 225) * (Math.PI / 180);
    const pointRadius = innerRadius;
    return {
      x: radius + pointRadius * Math.cos(angle),
      y: radius + pointRadius * Math.sin(angle),
    };
  };

  const pointPos = getPointPosition(animatedValue);

  return (
    <svg height={radius * 2} width={radius * 3}>
      <g transform={`translate(${radius * 0.5}, ${radius * 0.2})`}>
        {/* Background circle */}
        <circle
          r={innerRadius}
          cx={radius}
          cy={radius}
          stroke="transparent"
          strokeWidth={strokeWidth}
          fill="none"
          transform={transform}
        />
        {/* Render sections */}
        {normalizedSections.map((section, index) => {
          const sectionArc = valueToArcLength(section.start, section.end);
          const previousSections = normalizedSections.slice(0, index);
          const offset = previousSections.reduce(
            (acc, curr) => acc + valueToArcLength(curr.start, curr.end) + 2,
            0
          );

          const labelPos = getLabelPosition(section.start, section.end);
          const formattedLabel = formatLabel(section.label);
          const isFirst = index === 0;
          const isLast = index === normalizedSections.length - 1;

          return (
            <React.Fragment key={index}>
              <circle
                r={innerRadius}
                cx={radius}
                cy={radius}
                stroke={section.color}
                strokeWidth={strokeWidth}
                strokeDasharray={`${sectionArc}, ${circumference}`}
                strokeDashoffset={-(offset + arc / 4)}
                fill="none"
                transform={transform}
              />
              {!isLast && (
                <circle
                  r={innerRadius}
                  cx={radius}
                  cy={radius}
                  stroke="#000"
                  strokeWidth={2}
                  strokeDasharray={`1, ${circumference}`}
                  strokeDashoffset={-(offset + sectionArc + arc / 4)}
                  fill="none"
                  transform={transform}
                />
              )}
              {Array.isArray(formattedLabel) ? (
                <text
                  x={labelPos.x}
                  y={labelPos.y}
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fontSize={fontSize}
                  fontWeight="bold"
                  fill="#fff"
                >
                  <tspan x={labelPos.x} dy="-0.6em">
                    {formattedLabel[0]}
                  </tspan>
                  <tspan x={labelPos.x} dy="1.2em">
                    {formattedLabel[1]}
                  </tspan>
                </text>
              ) : (
                <text
                  x={labelPos.x}
                  y={labelPos.y}
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fontSize={fontSize}
                  fontWeight="bold"
                  fill="#fff"
                >
                  {formattedLabel}
                </text>
              )}
            </React.Fragment>
          );
        })}

        {/* Start point marker */}
        <circle
          cx={getPointPosition(0).x}
          cy={getPointPosition(0).y}
          r={strokeWidth / 2}
          fill={firstSectionColor}
          strokeWidth={0}
        />
        {/* End point marker */}
        <circle
          cx={getPointPosition(100).x}
          cy={getPointPosition(100).y}
          r={strokeWidth / 2}
          fill={lastSectionColor}
          strokeWidth={0}
        />

        {/* Render animated point marker */}
        <circle
          cx={pointPos.x}
          cy={pointPos.y}
          r={strokeWidth / 2}
          fill="white"
          stroke="black"
          strokeWidth={20}
          style={{
            transition: "r 0.2s ease-out",
          }}
          onMouseEnter={(e) => e.target.setAttribute("r", strokeWidth / 1.5)}
          onMouseLeave={(e) => e.target.setAttribute("r", strokeWidth / 2)}
        />
        <circle
          cx={pointPos.x}
          cy={pointPos.y}
          r={strokeWidth / 2.2}
          fill="white"
          style={{
            transition: "r 0.2s ease-out",
          }}
          onMouseEnter={(e) => e.target.setAttribute("r", strokeWidth / 3)}
          onMouseLeave={(e) => e.target.setAttribute("r", strokeWidth / 4)}
        />
      </g>
    </svg>
  );
}
