import React, { useState } from "react";
import { Modal, Text, Title, Flex, Badge, Box, Button } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

import { LoyaltyMembershipForm } from "./";
import DealerVerification from "@components/RideChallengeApp/DealerVerification";
import { StyledIframe } from "@components/shared/IframeModal";

export default function LoyaltyMembership({
  codeVerificationData,
  membership = null,
  additionalReqData = {},
  fetchData,
  setMembershipInfo,
  userInfo,
  showMembershipSignup = true,
}) {
  const [showSignup, setShowSignup] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);

  if (showSignup) {
    return (
      <div>
        <SignupFlow
          reqData={additionalReqData}
          onLeadCreate={() => {
            setShowSignup(false);
            setShowSignupModal(true);
          }}
          codeVerificationData={codeVerificationData}
        />
        <Flex justify="center" mt="xl">
          <Button variant="light" onClick={() => setShowSignup(false)}>
            I already have a membership
          </Button>
        </Flex>
      </div>
    );
  }

  return (
    <div>
      {membership ? (
        <Box>
          <Item info={membership} />
        </Box>
      ) : (
        <Box>
          {/* <Text c="white" fw={600}>
            Add a new membership
          </Text> */}
          <LoyaltyMembershipForm
            additionalReqData={additionalReqData}
            userInfo={userInfo}
            setMembershipInfo={(e) => {
              if (setMembershipInfo) {
                setMembershipInfo(e);
              }
              if (fetchData) {
                fetchData();
              }
            }}
          />
          {showMembershipSignup && (
            <Flex justify="center" mt="lg">
              <Button
                radius="lg"
                variant="light"
                onClick={() => setShowSignup(true)}
              >
                I need a membership
              </Button>
            </Flex>
          )}
          {/* <Modal
            opened={showSignupModal}
            onClose={() => setShowSignupModal(false)}
            size="lg"
          >
            <StyledIframe src="https://members.harley-davidson.com/HOG/HDMN_Login" />
          </Modal> */}
        </Box>
      )}
    </div>
  );
}

const Item = ({ info }) => {
  const [loading, setLoading] = useState(false);
  const [statusInfo, setStatusInfo] = useState(null);

  function onStatusCheck() {
    setLoading(true);

    const req = {
      loyalty_membership_id: info.id,
    };

    axios
      .post(`/loyalty-memberships/status-check/`, req)
      .then(({ data }) => {
        setLoading(false);
        setStatusInfo(data.response[0]);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <Flex justify="center" gap="xs" align="center">
        <Title order={1} c="white">
          {info.ident}
        </Title>
        {info.is_active ? (
          <Badge color="green" size="xs">
            Active
          </Badge>
        ) : (
          <Badge color="red" size="xs">
            Inactive
          </Badge>
        )}
      </Flex>
      {info.initial_activation_date && (
        <Text align="center" c="white" mb="sm">
          Member since{" "}
          {new Date(info.initial_activation_date).toLocaleDateString()}
        </Text>
      )}
      <Text align="center" size="xs" c="dimmed">
        last verified {new Date(info.last_verified_at).toLocaleString()}
      </Text>
      {statusInfo && (
        <Box mt="xs">
          <Text align="center" size="sm" c="white">
            Verified: {statusInfo.verified ? "Yes" : "No"}
          </Text>
          <Text align="center" size="sm" c="white">
            Active: {statusInfo.is_active ? "Yes" : "No"}
          </Text>
        </Box>
      )}
      {!info.loyalty_membership_id && (
        <Flex justify="center" mt="sm">
          <Button
            size="xs"
            // variant="light"
            onClick={onStatusCheck}
            loading={loading}
            radius="xl"
          >
            Check Status
          </Button>
        </Flex>
      )}
    </div>
  );
};

const SignupFlow = ({ onLeadCreate, reqData, codeVerificationData }) => {
  const [showDealerCode, setShowDealerCode] = useState(false);

  function createLead(remoteCodeData = null) {
    const req = { ...reqData, variety: 1 };
    delete req.check;

    if (remoteCodeData) {
      req.remote_code_id = remoteCodeData.id;
    }

    axios
      .post(`/leads/`, req)
      .then(() => {
        openMembership();
        onLeadCreate();
      })
      .catch((err) => {
        toast.error(err);
        openMembership();
        onLeadCreate();
      });
  }

  function openMembership() {
    setTimeout(() => {
      window.open(
        "https://members.harley-davidson.com/HOG/HDMN_Login",
        "_blank"
      );
    });
  }

  if (showDealerCode) {
    return (
      <div>
        <DealerVerification
          codeVerificationData={codeVerificationData}
          setDealerCodeData={(e) => {
            createLead(e);
          }}
        />
        <Flex justify="center" mt="lg">
          <Button variant="light" onClick={() => setShowDealerCode(false)}>
            Cancel
          </Button>
        </Flex>
      </div>
    );
  }

  return (
    <div>
      <Text c="white" fw={600} align="center" size="lg" mb="sm">
        Are you working with an HD rep?
      </Text>
      <Flex justify="center" gap="xl">
        <Button onClick={() => setShowDealerCode(true)}>Yes</Button>
        <Button color="red" onClick={createLead}>
          No
        </Button>
      </Flex>
    </div>
  );
};
