import React, { useState } from "react";
import {
  Box,
  Text,
  Flex,
  Badge,
  ThemeIcon,
  rem,
  ActionIcon,
  Progress,
  Card,
  NumberFormatter,
} from "@mantine/core";
import { IconCheck, IconCaretDownFilled } from "@tabler/icons-react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

export default function TieredContestUserProgress({ contestInfo }) {
  return (
    <div>
      {/* <Text size="xl" mb="lg" fw={600} c="white">
        {contestInfo.name}
      </Text> */}
      {contestInfo.results.map((tierGroup, i) => (
        <Box
          key={tierGroup.contest_tier_group_id}
          mb={i < contestInfo.results.length ? "xl" : "none"}
        >
          <>
            <Flex mb="sm" align="center">
              <Flex gap="xs" align="center">
                <Text fw={600} c="white">
                  {tierGroup.name}
                </Text>
                <Badge variant="light" color="gray" size="xs">
                  {tierGroup.variety_formatted}
                </Badge>
              </Flex>
            </Flex>
          </>
          {tierGroup.tier_results.length === 0 && (
            <Text c="white" size="xs">
              Nothing here yet
            </Text>
          )}
          {tierGroup.tier_results
            .sort((a, b) => a.position - b.position)
            .map((tier, tierIterator) => {
              const tierImage =
                tier.assets && tier.assets.length
                  ? tier.assets[0].filename_url
                  : null;

              return (
                <motion.div
                  key={tierIterator}
                  layout
                  initial={{ opacity: 0, y: 400, scale: 0.5 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  exit={{ opacity: 0, y: 200, scale: 1.2 }}
                  transition={{ duration: 0.6, type: "spring" }}
                >
                  <Card
                    style={{
                      opacity: !tier.earned && !tier.in_range ? 0.25 : 1,
                      background: tier.in_range
                        ? "var(--mantine-color-dark-8)"
                        : "var(--mantine-color-dark-8)",
                      borderRadius: "5px",
                      borderColor: "var(--mantine-color-dark-6)",
                    }}
                    mb="xs"
                  >
                    <ToggleSection
                      previewText="Tap to view progress"
                      preview={
                        <Flex gap="xs">
                          <div>
                            {tierImage ? (
                              <StyledImage
                                height={50}
                                width={50}
                                src={tierImage}
                              />
                            ) : (
                              <StyledPlaceholder height={50} width={50} />
                            )}
                          </div>
                          <div style={{ flexGrow: 1 }}>
                            <Flex align="center" gap="xs">
                              <Text c="white" fw={600}>
                                {tier.name}
                              </Text>
                              {tier.earned && (
                                <ThemeIcon radius="xl" size="xs" color="green">
                                  <IconCheck
                                    style={{
                                      height: rem(12),
                                      width: rem(12),
                                    }}
                                  />
                                </ThemeIcon>
                              )}
                            </Flex>
                            <Text size="xs" c="dimmed">
                              <NumberFormatter
                                thousandSeparator
                                value={tier.start_value}
                              />{" "}
                              -{" "}
                              <NumberFormatter
                                thousandSeparator
                                value={tier.end_value}
                              />{" "}
                              {tierGroupVarietyInfo[tierGroup.variety].units}
                            </Text>
                            <Progress
                              mt="sm"
                              value={tier.percent_complete}
                              radius="xl"
                              style={{
                                background: "var(--mantine-color-dark-6)",
                              }}
                            />
                          </div>
                        </Flex>
                      }
                    >
                      <Box
                        style={{
                          position: "relative",
                          left: "50px",
                          maxWidth: "calc(100% - 75px)",
                        }}
                        mt="xs"
                      >
                        {tier.prizes.map((prize, prizeIterator) => (
                          <Box
                            mb={
                              prizeIterator < tier.prizes.length - 1
                                ? "lg"
                                : "0"
                            }
                            key={prizeIterator}
                          >
                            <ToggleSection
                              preview={
                                <>
                                  <Text
                                    mb={prize.description ? 0 : "sm"}
                                    fw={600}
                                    c="white"
                                  >
                                    {prize.name}
                                  </Text>
                                  {prize.description && (
                                    <Text size="xs" mb="sm" c="dimmed">
                                      {prize.description}
                                    </Text>
                                  )}
                                </>
                              }
                            >
                              {prize.loot_items.map(
                                (lootItem, lootItemIterator) => {
                                  const lootItemImage =
                                    lootItem.assets && lootItem.assets.length
                                      ? lootItem.assets[0].filename_url
                                      : null;

                                  return (
                                    <Box key={lootItemIterator}>
                                      <Flex gap="xs" align="center">
                                        <div>
                                          {lootItemImage ? (
                                            <StyledImage
                                              src={lootItemImage}
                                              height={40}
                                              width={40}
                                            />
                                          ) : (
                                            <StyledPlaceholder
                                              height={40}
                                              width={40}
                                            />
                                          )}
                                        </div>
                                        <div>
                                          <Text size="sm" c="white">
                                            ({lootItem.quantity}){" "}
                                            {lootItem.name}
                                          </Text>
                                        </div>
                                      </Flex>
                                    </Box>
                                  );
                                }
                              )}
                            </ToggleSection>
                          </Box>
                        ))}
                      </Box>
                    </ToggleSection>
                  </Card>
                </motion.div>
              );
            })}
        </Box>
      ))}
    </div>
  );
}

const StyledImage = styled.img`
  width: ${(props) => (props.width ? `${props.width}px` : `100px`)};
  height: ${(props) => (props.height ? `${props.height}px` : `100px`)};
  object-fit: cover;
  border-radius: 5px;
`;

const StyledPlaceholder = styled.div`
  width: ${(props) => (props.width ? `${props.width}px` : `100px`)};
  height: ${(props) => (props.height ? `${props.height}px` : `100px`)};
  background: var(--mantine-color-gray-4);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const tierGroupVarietyInfo = {
  1: {
    name: "mileage",
    units: "miles",
  },
  2: {
    name: "visits",
    units: "visits",
  },
  3: {
    name: "keyword claims",
    units: "claims",
  },
  4: {
    name: "check-ins",
    units: "check-ins",
  },
};

const ToggleSection = ({ preview, children }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        maxWidth: "100%",
      }}
    >
      <AnimatePresence initial={false}>
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => setOpen(!isOpen)}
        >
          {preview}
        </div>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            // style={box}
            key="box"
            // style={ball}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
