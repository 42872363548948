import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Button,
  Modal,
  Text,
  Radio,
  Textarea,
  TextInput,
  ActionIcon,
} from "@mantine/core";
import { IconHelp } from "@tabler/icons-react";
import axios from "axios";
import toast from "react-hot-toast";
import { shallow } from "zustand/shallow";

import { emailValidation, phoneValidation } from "@util/validation";
import useAppStore from "./ride-challenge-store";

export default function DealerMode({
  border = true,
  children,
  dealerCodeData,
}) {
  return (
    <StyledDealerMode border={border}>
      {dealerCodeData && (
        <Text align="center" size="xs" c="dimmed">
          Validator: {dealerCodeData.first_name} {dealerCodeData.last_name}
        </Text>
      )}
      <>{children}</>
      <div className="help">
        <NeedHelp dealerCodeData={dealerCodeData} />
      </div>
    </StyledDealerMode>
  );
}

const StyledDealerMode = styled.div`
  background: var(--mantine-color-dark-8);
  padding: 1em 2em;
  border-radius: 0.25em;
  border: ${(props) =>
    props.border ? `1px solid var(--mantine-color-dark-6)` : "none"};
  position: relative;

  .help {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

const NeedHelp = ({ dealerCodeData }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    description: "",
    preferred_contact: "phone",
    email: "",
    phone: "",
  });

  const { effortId, user } = useAppStore(
    (state) => ({
      effortId: state.effortId,
      user: state.user,
    }),
    shallow
  );

  useEffect(() => {
    if (dealerCodeData) {
      setFormValues({
        ...formValues,
        // email: dealerCodeData.email,
        phone: dealerCodeData.number,
      });
    }
  }, [JSON.stringify(dealerCodeData)]);

  function onClose() {
    setShowModal(false);
    setLoading(false);
  }

  function onSubmit() {
    // setLoading(true);

    const req = {
      description: formValues.description,
      preferred_contact: formValues.preferred_contact,
      email: formValues.email,
      phone: formValues.phone || dealerCodeData.number,
      first_name: dealerCodeData.first_name,
      last_name: dealerCodeData.last_name,
      location_id: dealerCodeData.location_id,
      user_id: user?.id,
      campaign_effort_id: effortId,
    };

    axios
      .post("/ride-challenge/verifier-help/", req)
      .then(({ data }) => {
        setLoading(false);
        setShowModal(false);
        toast.success("Help Request Submitted!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const submitDisabled =
    !formValues.description ||
    (formValues.preferred_contact === "email" && !formValues.email) ||
    (formValues.preferred_contact === "phone" && !formValues.phone) ||
    (formValues.preferred_contact === "text" && !formValues.phone) ||
    (formValues.preferred_contact === "email" &&
      !emailValidation.test(formValues.email)) ||
    (formValues.preferred_contact === "phone" &&
      !phoneValidation.test(formValues.phone)) ||
    (formValues.preferred_contact === "text" &&
      !phoneValidation.test(formValues.phone));

  return (
    <div>
      <ActionIcon
        onClick={() => setShowModal(true)}
        radius="xl"
        variant="subtle"
      >
        <IconHelp size={16} />
      </ActionIcon>
      <Modal opened={showModal} onClose={onClose}>
        <Text mb="xs" fw={600}>
          Need help?
        </Text>
        <Radio.Group
          label="Preferred contact method"
          withAsterisk
          onChange={(e) =>
            setFormValues({ ...formValues, preferred_contact: e })
          }
          value={formValues.preferred_contact}
        >
          {contactMethods.map((method, i) => (
            <Radio
              mt="xs"
              key={i}
              mb="sm"
              value={method.value}
              label={method.label}
            />
          ))}
        </Radio.Group>
        {formValues.preferred_contact === "email" && (
          <TextInput
            label="Email"
            mb="sm"
            withAsterisk
            onChange={(e) =>
              setFormValues({ ...formValues, email: e.target.value })
            }
            value={formValues.email}
          />
        )}
        {(formValues.preferred_contact === "phone" ||
          formValues.preferred_contact === "text") && (
          <TextInput
            label="Phone"
            withAsterisk
            mb="sm"
            onChange={(e) =>
              setFormValues({ ...formValues, phone: e.target.value })
            }
            value={formValues.phone}
          />
        )}
        <Textarea
          label="Issue"
          description="Please describe the issue you are experiencing."
          withAsterisk
          onChange={(e) =>
            setFormValues({ ...formValues, description: e.target.value })
          }
          value={formValues.description}
        />
        <Button
          disabled={submitDisabled}
          fullWidth
          mt="md"
          onClick={onSubmit}
          loading={loading}
        >
          Submit
        </Button>
      </Modal>
    </div>
  );
};

const contactMethods = [
  {
    label: "Phone",
    value: "phone",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Text",
    value: "text",
  },
];
