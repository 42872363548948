import React, { useState, useRef, useEffect, useMemo } from "react";
import axios from "axios";
import {
  Modal,
  ActionIcon,
  Button,
  Card,
  Divider,
  Flex,
  Loader,
  Title,
  TextInput,
  Pagination,
  Table,
  Text,
  Box,
  UnstyledButton,
  SegmentedControl,
  Switch,
  Select,
} from "@mantine/core";
import { Link } from "react-router-dom";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { IconExclamationMark } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

import { TicketEntryForm } from "@components/Ticket";
import { LoyaltyMembership } from "@components/LoyaltyMembership";
import { OdometerForm } from "@components/RideChallengeApp/VehicleVerificationView";
import { Notes } from "@components/shared";

const statusOptions = [
  { label: "All", value: "entire" },
  { label: "Waiting", value: "waiting" },
  { label: "Queued", value: "queued" },
  { label: "Sent", value: "sent" },
  { label: "Delivered", value: "delivered" },
  { label: "Opened", value: "opened" },
  { label: "Bounced", value: "bounced" },
  { label: "Errored", value: "errored" },
];

export default function RideChallengeManagementList({ effortId, refresh }) {
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(-1);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState({
    scope: "entire",
    status: "entire",
  });
  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const columns = [
    {
      header: "Name",
      // accessorKey: "recipient_name",
      id: "id",
      enableSorting: false,
      cell: (info) => {
        const { original } = info.row;
        return (
          <UnstyledButton
            component={Link}
            to={`/ride-challenge-interactions/${effortId}/${original.user_id}`}
            target="_blank"
          >
            <Text size="sm" td="underline">
              {original.first_name} {original.last_name}
            </Text>
          </UnstyledButton>
        );
      },
    },
    {
      header: "Email",
      accessorKey: "email",
      id: "email",
      enableSorting: false,
      cell: (info) => info.getValue(),
    },
    {
      header: "Phone",
      accessorKey: "mobile_phone",
      id: "phone",
      enableSorting: false,
      cell: (info) => info.getValue(),
    },
    {
      header: "Registered",
      accessorKey: "information.registered",
      id: "registered",
      enableSorting: false,
      cell: (info) => (info.getValue() === true ? "Yes" : "No"),
    },
    {
      header: "Entered",
      accessorKey: "information.entered",
      id: "entered",
      enableSorting: false,
      cell: (info) => (info.getValue() === true ? "Yes" : "No"),
    },
    {
      header: "Odometer",
      accessorKey: "information.odometer_total",
      id: "odometer",
      enableSorting: false,
      cell: (info) => info.getValue(),
    },
  ];

  const table = useReactTable({
    data: items,
    columns,
    manualPagination: true,
    enableMultiSort: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
  });

  useEffect(() => {
    let p = new Promise((resolve, reject) => {
      resolve(table.setPageIndex(0));
    });
    p.then((result) => {
      fetchData();
    }).catch((error) => {
      console.log(error);
    });
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    table.setPageIndex(0);
    if (table.getState().pagination.pageIndex === 0) {
      fetchData();
    }
  }, [searchValue]);

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(table.getState().pagination), refresh]);

  function onSearchSubmit() {
    let p = new Promise((resolve, reject) => {
      resolve(table.setPageIndex(0));
    });
    p.then(() => {
      fetchData();
    }).catch((error) => {
      console.log(error);
    });
  }

  function fetchData() {
    setLoading(true);

    const cancel = cancelRef.current;
    if (cancel) cancel();

    const req = {
      page_size: table.getState().pagination.pageSize,
      page:
        table.getState().pagination.pageIndex === -1
          ? 0
          : table.getState().pagination.pageIndex,
      search_value: searchValue,
      status: filters.status,
      campaign_effort_id: effortId,
    };

    axios
      .post(`/efforts/${effortId}/retrieve-management-table/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setItems(data.response[0].data);
        setTotal(data.response[0].total);
        setPageCount(data.response[0].page_count);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
        setTotal(0);
        setPageCount(1);
      });
  }

  return (
    <div>
      <Flex gap="xs" align="center" mb="sm">
        <Title order={4}>Ride Challenge Management</Title>
        {loading && <Loader size="xs" />}
      </Flex>
      <Flex style={{ width: "100%" }} gap="xs" align="center">
        <TextInput
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search..."
          style={{
            flexGrow: 1,
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSearchSubmit();
            }
          }}
        />
      </Flex>
      <SegmentedControl
        value={filters.scope}
        data={[
          { label: "All", value: "entire" },
          { label: "HOG Members", value: "hog_members" },
          { label: "Non-Hog Members", value: "non_hog_members" },
        ]}
        size="xs"
        onChange={(e) =>
          setFilters({
            ...filters,
            scope: e,
          })
        }
        mt="sm"
      />
      <Card mt="sm">
        <Table.ScrollContainer minWidth={1000}>
          <Table striped>
            <Table.Thead>
              <Table.Tr>
                {table.getFlatHeaders().map((header) => (
                  <Table.Th key={header.id}>
                    {header.column.columnDef.header}
                  </Table.Th>
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Table.Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Table.Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Td>
                      );
                    })}
                  </Table.Tr>
                );
              })}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </Card>
      <Pagination
        value={table.getState().pagination.pageIndex + 1}
        onChange={(e) => {
          table.setPageIndex(e - 1);
        }}
        total={pageCount}
        mt="lg"
      />
    </div>
  );
}

const RideChallengeItem = ({ rawData, id, effortId }) => {
  const [isOpen, setOpen] = useState(false);
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);

  const managerInfo = useSelector((state) => state.manager);

  useEffect(() => {
    setError(null);
    if (!isOpen) return;
    fetchData();
  }, [isOpen]);

  function fetchData() {
    const req = {
      campaign_effort_id: effortId,
      user_id: rawData.user_id,
    };

    axios
      .post(`/efforts/${effortId}/retrieve-management-detail/`, req)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  function onClose() {
    setOpen(false);
  }

  const userVehicleDataPoints = info
    ? info.user_vehicle_data_points.map((m) => ({
        ...m,
        user_vehicle: info.user_vehicles.find(
          (f) => f.id === m.user_vehicle_id
        ),
      }))
    : [];

  const rideChallengeDataPoints = info
    ? info.ride_challenge_data_points.map((m) => ({
        ...m,
        user_vehicle: info.user_vehicles.find(
          (f) => f.id === m.user_vehicle_id
        ),
      }))
    : [];

  return (
    <>
      <UnstyledButton
        component={Link}
        to={`/ride-challenge-interactions/${effortId}/${rawData.user_id}`}
        target="_blank"
      >
        {rawData ? (
          <Text size="sm" td="underline">
            {rawData.first_name} {rawData.last_name}
          </Text>
        ) : (
          <Text size="sm" td="underline">
            {rawData.id}
          </Text>
        )}
      </UnstyledButton>
      <Modal size="xl" opened={isOpen} onClose={onClose}>
        {error && (
          <Text c="red" fw={600}>
            {error}
          </Text>
        )}
        {rawData.information.user_info && (
          <>
            <Text fw={600}>
              {rawData.information.user_info.first_name}{" "}
              {rawData.information.user_info.last_name}
            </Text>
            <Text>{rawData.information.user_info.email}</Text>
            <Text>{rawData.information.user_info.phone}</Text>
            <Divider mt="lg" mb="lg" />
          </>
        )}
        {info && (
          <>
            <Text mb="sm">Loyalty Membership</Text>
            <Box
              p="lg"
              style={{
                background: "var(--mantine-color-dark-5)",
                borderRadius: "10px",
              }}
            >
              <LoyaltyMembership
                membership={
                  info && info.loyalty_memberships.length > 0
                    ? info.loyalty_memberships[0]
                    : null
                }
                additionalReqData={{
                  variety: 1,
                  in_use: true,
                  user_id: rawData.user_id,
                  user_location_id: rawData.id,
                  campaign_effort_id: effortId,
                }}
                fetchData={fetchData}
              />
            </Box>
            <Divider mt="lg" mb="lg" />
            <Text mb="sm">Vehicles: {info?.user_vehicles.length}</Text>
            {info?.user_vehicles.map((vehicle) => (
              <Box mb="sm">
                <Text fw={600} key={vehicle.id}>
                  {vehicle.year} {vehicle.make} {vehicle.model}
                  {vehicle.series ? ` ${vehicle.series}` : ""}
                </Text>
                <Text size="sm" c="dimmed">
                  {vehicle.vin}
                </Text>
                <Text size="sm" c="dimmed">
                  added at {vehicle.added_at_location_id}
                </Text>
              </Box>
            ))}
            <Divider mt="lg" mb="lg" />
            <Text mb="sm">
              User Vehicle Data Points: {info?.user_vehicle_data_points.length}
            </Text>
            {userVehicleDataPoints.map((datapoint) => {
              return (
                <Box mb="sm" key={datapoint.id}>
                  <Datapoint info={datapoint} fetchData={fetchData} />
                </Box>
              );
            })}
            <Divider mt="lg" mb="lg" />
            {!managerInfo && (
              <>
                <Text>Add Data Point</Text>
                <DatapointForm
                  onSuccess={fetchData}
                  reqData={{
                    user_id: rawData.user_id,
                  }}
                  vehicles={info.user_vehicles}
                />
                <Divider mt="lg" mb="lg" />
              </>
            )}
            <Text mb="sm">
              Ride Challenge Data Points:{" "}
              {info.ride_challenge_data_points.length}
            </Text>
            {rideChallengeDataPoints.map((datapoint) => {
              return (
                <Box mb="sm">
                  <Text fw={600} key={datapoint.id}>
                    {datapoint.odometer_value} miles
                  </Text>
                  {datapoint.user_vehicle && (
                    <Text size="sm" c="dimmed">
                      {datapoint.user_vehicle.year}{" "}
                      {datapoint.user_vehicle.make}{" "}
                      {datapoint.user_vehicle.model}
                      {datapoint.user_vehicle.series
                        ? ` ${datapoint.user_vehicle.series}`
                        : ""}
                    </Text>
                  )}
                  <Text size="sm" c="dimmed">
                    {new Date(datapoint.created_at).toLocaleString()}
                  </Text>
                </Box>
              );
            })}
          </>
        )}
      </Modal>
    </>
  );
};

const Datapoint = ({ info, fetchData }) => {
  const [ticketOpen, setTicketOpen] = useState(false);
  const [notesOpen, setNotesOpen] = useState(false);

  const managerInfo = useSelector((state) => state.manager);

  return (
    <>
      <Text fw={600}>{info.odometer_value} miles</Text>
      {info.user_vehicle && (
        <Text size="sm" c="dimmed">
          {info.user_vehicle.year} {info.user_vehicle.make}{" "}
          {info.user_vehicle.model}
          {info.user_vehicle.series ? ` ${info.user_vehicle.series}` : ""}
        </Text>
      )}
      <Text size="sm" c="dimmed">
        {new Date(info.created_at).toLocaleString()}
      </Text>
      {!managerInfo && (
        <Box mt="xs">
          <StatusToggle
            onSuccess={() => fetchData()}
            checked={info.is_active}
            reqData={{
              user_vehicle_data_point_id: info.id,
            }}
          />
        </Box>
      )}
      {managerInfo && (
        <>
          <Button
            onClick={() => setTicketOpen(true)}
            size="xs"
            mt="sm"
            color="red"
            variant="light"
          >
            Report a problem
          </Button>
          <Modal
            opened={ticketOpen}
            onClose={() => {
              setTicketOpen(false);
            }}
          >
            <TicketEntryForm
              isManager
              showTitle={false}
              issueLabel="What's the problem here?"
              reqData={{
                first_name: managerInfo.full_name.split(" ")[0],
                last_name: managerInfo.full_name.split(" ")[1],
                email: managerInfo.email,
                user_vehicle_id: info.user_vehicle_id,
                user_vehicle_data_point_id: info.id,
                phone: "",
              }}
            />
          </Modal>
        </>
      )}
      {!managerInfo && (
        <>
          <Button
            onClick={() => setNotesOpen(true)}
            size="xs"
            mt="sm"
            variant="light"
          >
            Notes
          </Button>
          <Modal
            opened={notesOpen}
            onClose={() => {
              setNotesOpen(false);
            }}
          >
            <Notes
              additionalCreateData={{
                user_vehicle_data_point_id: info.id,
              }}
              additionalFetchData={{
                user_vehicle_data_point_id: info.id,
              }}
              closable={false}
            />
          </Modal>
        </>
      )}
    </>
  );
};

const StatusToggle = ({ reqData, checked, onSuccess }) => {
  function onChange() {
    axios
      .post(`/user-vehicle-data-points/toggle-active/`, reqData)
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return <Switch label="Active" checked={checked} onChange={onChange} />;
};

const DatapointForm = ({ onSuccess, vehicles }) => {
  const initialFormValues = {
    variety: 1,
    user_vehicle_id: "",
    odometer_value: "",
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const [loading, setLoading] = useState(false);

  function onSubmit() {
    const req = {
      ...formValues,
    };

    setLoading(true);

    axios
      .post(`/user-vehicles/${formValues.user_vehicle_id}/add-data-point/`, req)
      .then(() => {
        setFormValues({
          ...formValues,
          odometer_value: "",
        });
        setLoading(false);
        toast.success("Created!");
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  const formattedVehicles = vehicles.map((m) => ({
    value: m.id,
    label: `${m.year} ${m.make} ${m.model}${m.series ? ` ${m.series}` : ""} - ${
      m.vin
    }`,
  }));

  return (
    <div>
      <Select
        label="Vehicle"
        data={formattedVehicles}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            user_vehicle_id: e,
          })
        }
        value={formValues.user_vehicle_id}
        placeholder="Select one..."
      />
      <Flex
        justify="center"
        mt="sm"
        p="lg"
        style={{
          background: "var(--mantine-color-dark-5)",
          borderRadius: "10px",
        }}
      >
        <OdometerForm
          value={formValues.odometer_value}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              odometer_value: e,
            })
          }
        />
      </Flex>
      <Button
        fullWidth
        onClick={onSubmit}
        loading={loading}
        disabled={!formValues.user_vehicle_id || !formValues.odometer_value}
        mt="md"
      >
        Create
      </Button>
    </div>
  );
};
