import React, { useState, useRef, useEffect, useMemo } from "react";
import axios from "axios";
import {
  Modal,
  Button,
  Flex,
  Text,
  Box,
  Switch,
  Table,
  ActionIcon,
  rem,
  Loader,
  UnstyledButton,
  Code,
} from "@mantine/core";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { IconNotes, IconThumbUp, IconThumbDown } from "@tabler/icons-react";

import { TicketEntryForm } from "@components/Ticket";
import { OdometerForm } from "@components/RideChallengeApp/VehicleVerificationView";
import { Notes } from "@components/shared";
import { LocationSelect } from "@components/Location";

const UserVehicleDatapoints = ({
  datapoints,
  fetchData,
  vehicleId,
  userId,
  children,
  showStatus,
  showEdit,
  effortId,
  vehicleStatus,
}) => {
  const [showDatapointAdd, setShowDatapointAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const managerInfo = useSelector((state) => state.manager);
  const adminInfo = useSelector((state) => state.admin);

  function onAddDatapointClose() {
    setShowDatapointAdd(false);
  }

  return (
    <>
      <Flex gap="xs" mt="lg" mb="xs" align="center">
        <Text fw={600}>Datapoints</Text>
        {adminInfo && vehicleStatus === 1 && (
          <Button
            onClick={() => setShowDatapointAdd(true)}
            size="xs"
            radius="xl"
          >
            Add Datapoint
          </Button>
        )}
      </Flex>
      <Modal opened={showDatapointAdd} onClose={onAddDatapointClose}>
        <LocationSelect
          reqData={{
            campaign_effort_id: effortId,
          }}
          value={selectedLocationId}
          onChange={(e) => setSelectedLocationId(e)}
        />
        {selectedLocationId && (
          <DatapointForm
            onSuccess={() => {
              fetchData();
              onAddDatapointClose();
              setSelectedLocationId("");
            }}
            reqData={{
              user_id: userId,
              location_id: selectedLocationId,
            }}
            vehicleId={vehicleId}
          />
        )}
      </Modal>
      {children && (
        <Box mt="md" mb="md">
          {children}
        </Box>
      )}
      <DatapointTable
        datapoints={datapoints}
        fetchData={fetchData}
        showStatus={showStatus}
        showEdit={showEdit}
      />
    </>
  );
};

export const DatapointTable = ({
  datapoints,
  fetchData,
  showStatus,
  showEdit,
  vehicleStatus,
}) => {
  const managerInfo = useSelector((state) => state.manager);

  return (
    <Table striped>
      <Table.Thead>
        <Table.Tr>
          {showStatus && <Table.Th w={125}>Status</Table.Th>}
          <Table.Th w={200}>Date</Table.Th>
          <Table.Th>Location</Table.Th>
          <Table.Th>Verifier</Table.Th>
          <Table.Th>Reading</Table.Th>
          <Table.Th>RCDP</Table.Th>
          {!managerInfo && <Table.Th>API Send</Table.Th>}
          {!managerInfo && <Table.Th>Notes</Table.Th>}
          {managerInfo && <Table.Th></Table.Th>}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {datapoints.length === 0 && (
          <Table.Tr>
            <Table.Td colSpan={10}>No datapoints found</Table.Td>
          </Table.Tr>
        )}
        {datapoints.map((datapoint) => {
          return (
            <Table.Tr key={datapoint.id}>
              <Datapoint
                info={datapoint}
                fetchData={fetchData}
                showStatus={showStatus}
                showEdit={showEdit}
                vehicleStatus={vehicleStatus}
              />
            </Table.Tr>
          );
        })}
      </Table.Tbody>
    </Table>
  );
};

const Datapoint = ({
  info,
  showStatus = false,
  showEdit = false,
  fetchData,
  vehicleStatus,
}) => {
  const [ticketOpen, setTicketOpen] = useState(false);
  const [notesOpen, setNotesOpen] = useState(false);

  const managerInfo = useSelector((state) => state.manager);

  return (
    <>
      {showStatus && (
        <>
          {!managerInfo && showEdit ? (
            <Table.Td>
              <StatusToggle
                vehicleStatus={vehicleStatus}
                onSuccess={() => fetchData()}
                checked={info.is_active}
                reqData={{
                  user_vehicle_data_point_id: info.id,
                }}
              />
            </Table.Td>
          ) : (
            <Table.Td>{info.is_active ? "Active" : "Inactive"}</Table.Td>
          )}
        </>
      )}
      <Table.Td>{new Date(info.created_at).toLocaleString()}</Table.Td>
      <Table.Td>{info.location_name}</Table.Td>
      <Table.Td>{info.verifier_name}</Table.Td>
      <Table.Td>{info.odometer_value} miles</Table.Td>
      <Table.Td>
        <Rcdp info={info.ride_challenge_data_point} />
      </Table.Td>
      {managerInfo && (
        <Table.Td>
          <Button
            onClick={() => setTicketOpen(true)}
            size="xs"
            color="red"
            variant="light"
          >
            Report a problem
          </Button>
          <Modal
            opened={ticketOpen}
            onClose={() => {
              setTicketOpen(false);
            }}
          >
            <TicketEntryForm
              isManager
              showTitle={false}
              issueLabel="What's the problem here?"
              reqData={{
                first_name: managerInfo.full_name.split(" ")[0],
                last_name: managerInfo.full_name.split(" ")[1],
                email: managerInfo.email,
                user_vehicle_id: info.user_vehicle_id,
                user_vehicle_data_point_id: info.id,
                phone: "",
              }}
            />
          </Modal>
        </Table.Td>
      )}
      {!managerInfo && (
        <Table.Td>
          <ApiSend
            requestData={info.api_data_request}
            responseData={info.api_data_response}
            apiStatus={info.api_status}
          />
        </Table.Td>
      )}
      {!managerInfo && (
        <Table.Td>
          <ActionIcon
            onClick={() => setNotesOpen(true)}
            size="sm"
            variant="light"
            color="gray"
            radius="xl"
          >
            <IconNotes style={{ width: rem(16), height: rem(16) }} />
          </ActionIcon>
          <Modal
            opened={notesOpen}
            onClose={() => {
              setNotesOpen(false);
            }}
          >
            <Notes
              additionalCreateData={{
                user_vehicle_data_point_id: info.id,
              }}
              additionalFetchData={{
                user_vehicle_data_point_id: info.id,
              }}
              closable={false}
            />
          </Modal>
        </Table.Td>
      )}
    </>
  );
};

const StatusToggle = ({ reqData, checked, onSuccess, vehicleStatus }) => {
  const [confirm, setConfirm] = useState(false);

  function onConfirm() {
    axios
      .post(`/user-vehicle-data-points/toggle-active/`, reqData)
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  if (confirm) {
    return (
      <div>
        <Text mb="sm" align="center" size="xs">
          Deactivate?
        </Text>
        <Flex justify="center" gap="xs" align="center">
          <ActionIcon
            color="green"
            variant="light"
            radius="xl"
            title="Yes"
            onClick={onConfirm}
          >
            <IconThumbUp style={{ width: rem(16), height: rem(16) }} />
          </ActionIcon>
          <ActionIcon
            onClick={() => setConfirm(false)}
            color="red"
            variant="light"
            radius="xl"
            title="No"
          >
            <IconThumbDown style={{ width: rem(16), height: rem(16) }} />
          </ActionIcon>
        </Flex>
      </div>
    );
  }

  return (
    <Button
      color="gray"
      variant="light"
      size="xs"
      onClick={() => setConfirm(true)}
    >
      Deactivate
    </Button>
  );

  return (
    <Switch
      label="Active"
      checked={checked}
      // onChange={onChange}
      onChange={() => setConfirm(true)}
      disabled={vehicleStatus === 2}
    />
  );
};

const DatapointForm = ({ onSuccess, vehicleId, reqData }) => {
  const initialFormValues = {
    variety: 1,
    user_vehicle_id: vehicleId,
    odometer_value: "",
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const [loading, setLoading] = useState(false);

  function onSubmit() {
    const req = {
      ...reqData,
      ...formValues,
    };

    setLoading(true);

    axios
      .post(`/user-vehicles/${formValues.user_vehicle_id}/add-data-point/`, req)
      .then(() => {
        setFormValues({
          ...formValues,
          odometer_value: "",
        });
        setLoading(false);
        toast.success("Created!");
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <div>
      <Flex
        justify="center"
        mt="sm"
        p="lg"
        style={{
          background: "var(--mantine-color-dark-5)",
          borderRadius: "10px",
        }}
      >
        <OdometerForm
          value={formValues.odometer_value}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              odometer_value: e,
            })
          }
        />
      </Flex>
      <Button
        fullWidth
        onClick={onSubmit}
        loading={loading}
        disabled={!formValues.user_vehicle_id || !formValues.odometer_value}
        mt="md"
      >
        Create
      </Button>
    </div>
  );
};

export default UserVehicleDatapoints;

const ApiSend = ({ apiStatus, responseData, requestData }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  if (apiStatus === 1) {
    return "n/a";
  }

  return (
    <>
      <UnstyledButton onClick={() => setOpen(true)}>
        <Text c={apiStatus === 2 ? "red" : "green"} fw={600} size="sm">
          {apiStatus === 2 ? "Failed" : "Sent"}
        </Text>
      </UnstyledButton>
      <Modal opened={isOpen} onClose={onClose}>
        {requestData && (
          <>
            <Text fw={600} size="xs">
              Request
            </Text>
            <Code
              style={{
                wordBreak: "break-all",
              }}
            >
              {Object.keys(requestData).map((key, i) => (
                <span key={i}>
                  {key}: {JSON.stringify(requestData[key])}
                  <br />
                </span>
              ))}
            </Code>
          </>
        )}
        {responseData && (
          <>
            <Text fw={600} size="xs">
              Response
            </Text>
            <Code
              style={{
                wordBreak: "break-all",
              }}
            >
              {Object.keys(responseData).map((key, i) => (
                <span key={i}>
                  {key}: {JSON.stringify(responseData[key])}
                  <br />
                </span>
              ))}
            </Code>
          </>
        )}
      </Modal>
    </>
  );
};

const Rcdp = ({ info }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  if (!info) return "-";

  return (
    <>
      <UnstyledButton
        style={{ textDecoration: "underline" }}
        onClick={() => setOpen(true)}
      >
        <Text size="sm">View</Text>
      </UnstyledButton>
      <Modal opened={isOpen} onClose={onClose}>
        <Code
          style={{
            wordBreak: "break-all",
          }}
        >
          {Object.keys(info).map((key, i) => (
            <span key={i}>
              {key}: {JSON.stringify(info[key])}
              <br />
            </span>
          ))}
        </Code>
      </Modal>
    </>
  );
};
