import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import {
  Title,
  Divider,
  Flex,
  Box,
  Text,
  Button,
  Grid,
  UnstyledButton,
  Badge,
} from "@mantine/core";
import axios from "axios";
import {
  IconWheel,
  IconUser,
  IconCalendar,
  IconExclamationCircle,
  IconAward,
  IconMedal,
  IconLocationFilled,
} from "@tabler/icons-react";
import styled from "styled-components";
import { motion } from "framer-motion";

import ViewTitle from "./ViewTitle";
import { Wrapper } from "./index";
import useAppStore from "./ride-challenge-store";

import RevScore from "./RevScore";
import UserMilestones from "./UserMilestones";
import DashboardContests from "./DashboardContests";
import DashboardVehicles from "./DashboardVehicles";
import DashboardMembership from "./DashboardMembership";
import DashboardUnverified from "./DashboardUnverified";
import DashboardHistory from "./DashboardHistory";
import RideChallengeKeywordForm from "./RideChallengeKeywordForm";
import RideChallengeAlerts from "./RideChallengeAlerts";
import { IframeModal } from "@components/shared";
import { StyledImage, StyledPlaceholder } from "./UserHistory";
import logo from "@assets/images/lets-ride-logo.png";
import hogLogo from "@assets/images/hog-logo-color.png";

export const views = {
  dashboard: "dashboard",
  membership: "membership",
  vehicles: "vehicles",
  unverified_data: "unverified_data",
  keyword: "keyword",
  rev_score: "rev_score",
  milestones: "milestones",
  contest: "contest",
  history: "history",
};

export default function RideChallengeDashboard() {
  const {
    alerts,
    appConfig,
    appLoading,
    coords,
    dashboardData,
    effortId,
    email,
    entityInfo,
    location,
    phone,
    setAppLoading,
    setDashboardData,
    user,
  } = useAppStore(
    (state) => ({
      alerts: state.alerts,
      appConfig: state.appConfig,
      appLoading: state.appLoading,
      coords: state.coords,
      dashboardData: state.dashboardData,
      effortId: state.effortId,
      email: state.email,
      entityInfo: state.entityInfo,
      location: state.location,
      phone: state.phone,
      setAppLoading: state.setAppLoading,
      setDashboardData: state.setDashboardData,
      user: state.user,
    }),
    shallow
  );
  const [view, setView] = useState(views.dashboard);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (view !== views.dashboard) return;
    fetchData();
  }, [view]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [view]);

  const foundEffort = user.campaign_efforts.find((f) => f.id === effortId);

  function fetchData() {
    const req = {
      user_id: user?.id,
    };

    const locationId = entityInfo.location_id || location ? location.id : null;

    setError(null);

    if (locationId) req.location_id = locationId;
    if (effortId) req.campaign_effort_id = effortId;

    if (email) req.email = email;
    if (phone) req.mobile_phone = phone;

    if (coords) {
      req.latitude = coords.latitude;
      req.longitude = coords.longitude;
    }

    setAppLoading(true);

    axios
      .post(`/ride-challenge/app-dashboard/`, req)
      .then(({ data }) => {
        setDashboardData(data.response[0]);
        setAppLoading(false);
        // const usr = data.response[0];

        // setUser(usr);

        // const foundEffort = usr?.campaign_efforts.find(
        //   (f) => f.id === effortId
        // );

        // if (foundEffort) {
        //   setAppLoading(false);
        //   if (!foundEffort.registered) return;

        //   if (foundEffort.registered && !foundEffort.entered) {
        //     if (foundEffort.has_unverified_readings) {
        //       return setActiveStep("dashboard");
        //     } else {
        //       return setActiveStep("vehicle");
        //     }
        //   }
        // }
      })
      .catch((err) => {
        setError(err);
        setAppLoading(false);
        // setUser(null);
      });
  }

  if (!dashboardData)
    return (
      <Wrapper>
        <div style={{ minHeight: "300px" }}>
          <Header activeView={view} />
        </div>
      </Wrapper>
    );

  if (error) {
    return (
      <Wrapper>
        <div>
          <Header activeView={view} />
          <div style={{ minHeight: "100px" }}>
            <Text align="center" fw={600} c="red">
              {error}
            </Text>
            <Flex justify="center" mt="lg">
              <Button onClick={fetchData} size="xs" variant="light">
                Reload
              </Button>
            </Flex>
          </div>
        </div>
      </Wrapper>
    );
  }

  if (view === views.milestones) {
    return (
      <Wrapper>
        <div>
          <Header activeView={view} />
          <AnimatedWrapper>
            <ViewTitle title={`My Milestones`} />
            <div style={{ minHeight: "250px" }}>
              <UserMilestones
                loading={appLoading}
                setAppLoading={(val) => setAppLoading(val)}
                reqData={{
                  campaign_effort_id: effortId,
                  user_id: user.id,
                }}
              />
            </div>
            <Box mt="xl">
              <DashboardButton onClick={() => setView(views.dashboard)} />
            </Box>
          </AnimatedWrapper>
        </div>
      </Wrapper>
    );
  }

  if (view === views.history) {
    return (
      <Wrapper>
        <div>
          <Header activeView={view} />
          <AnimatedWrapper>
            <ViewTitle title={`My History`} />
            <div style={{ minHeight: "250px" }}>
              <DashboardHistory />
            </div>
            <Box mt="xl">
              <DashboardButton onClick={() => setView(views.dashboard)} />
            </Box>
          </AnimatedWrapper>
        </div>
      </Wrapper>
    );
  }

  if (view === views.rev_score) {
    return (
      <Wrapper>
        <div>
          <Header activeView={view} />
          <RevScore
            color={appConfig.colors.base}
            reqData={{
              user_id: user.id,
              campaign_effort_id: foundEffort.id,
            }}
            expanded
            tiers={dashboardData.contest_tiers.results[0].tier_results}
          />
          <Box mt="xl">
            <DashboardButton onClick={() => setView(views.dashboard)} />
          </Box>
        </div>
      </Wrapper>
    );
  }

  if (view === views.contest) {
    return (
      <Wrapper>
        <div>
          <Header activeView={view} />
          <AnimatedWrapper>
            {/* <Flex justify="center" mb="sm">
            <IconAward
              size={48}
              style={{
                stroke: appConfig.colors.icon,
              }}
            />
          </Flex> */}
            <ViewTitle title="My Progress" />
            <DashboardContests />
            <Box mt="xl">
              <DashboardButton onClick={() => setView(views.dashboard)} />
            </Box>
          </AnimatedWrapper>
        </div>
      </Wrapper>
    );
  }

  if (view === views.vehicles) {
    return (
      <Wrapper>
        <div>
          <Header activeView={view} />
          <AnimatedWrapper>
            {/* <Flex justify="center" mb="sm">
            <IconWheel
              size={48}
              style={{
                stroke: appConfig.colors.icon,
              }}
            />
          </Flex> */}
            <DashboardVehicles
              setDashboardView={() => setView(views.dashboard)}
              setUnverifiedView={() => setView(views.unverified_data)}
              fetchData={fetchData}
            >
              <DashboardButton onClick={() => setView(views.dashboard)} />
            </DashboardVehicles>
          </AnimatedWrapper>
        </div>
      </Wrapper>
    );
  }

  if (view === views.membership) {
    return (
      <Wrapper>
        <div>
          {/* <Header /> */}
          <AnimatedWrapper>
            {/* <Flex justify="center" mb="sm">
            <IconUser
              size={48}
              style={{
                stroke: appConfig.colors.icon,
              }}
            />
          </Flex> */}
            {/* <ViewTitle title={`Membership`} /> */}
            <DashboardMembership />
            <Box mt="xl">
              <DashboardButton onClick={() => setView(views.dashboard)} />
            </Box>
          </AnimatedWrapper>
        </div>
      </Wrapper>
    );
  }

  if (view === views.unverified_data) {
    return (
      <Wrapper>
        <div>
          <Header activeView={view} />
          <AnimatedWrapper>
            {/* <Flex justify="center" mb="sm">
            <IconExclamationCircle
              size={48}
              style={{
                stroke: "var(--mantine-color-red-7)",
              }}
            />
          </Flex> */}
            <ViewTitle title={`These items need attention`} />
            <div style={{ minHeight: "150px" }}>
              <DashboardUnverified setView={() => setView(views.dashboard)}>
                <DashboardButton onClick={() => setView(views.dashboard)} />
              </DashboardUnverified>
            </div>
          </AnimatedWrapper>
        </div>
      </Wrapper>
    );
  }

  const navButtonConfig = {
    size: 28,
    color: "#000",
  };

  const navButtons = [
    {
      text: "Log Miles",
      icon: (
        <IconWheel
          size={navButtonConfig.size}
          style={{ stroke: navButtonConfig.color }}
        />
      ),
      onClick: () => setView(views.vehicles),
    },
    {
      text: "My History",
      icon: (
        <IconCalendar
          size={navButtonConfig.size}
          style={{ stroke: navButtonConfig.color }}
        />
      ),
      onClick: () => {
        setView(views.history);
        setAppLoading(true);
      },
    },
    {
      text: "Milestones",
      icon: (
        <IconMedal
          size={navButtonConfig.size}
          style={{ stroke: navButtonConfig.color }}
        />
      ),
      onClick: () => setView(views.milestones),
      has_alert: alerts.some((alert) => alert.milestone_id),
    },
    {
      text: "Tier Progress",
      icon: (
        <IconAward
          size={navButtonConfig.size}
          style={{ stroke: navButtonConfig.color }}
        />
      ),
      onClick: () => setView(views.contest),
    },
    {
      text: "Manage My Membership",
      icon: (
        <IconUser
          size={navButtonConfig.size}
          style={{ stroke: navButtonConfig.color }}
        />
      ),
      onClick: () => setView(views.membership),
      span: 12,
      centered: true,
    },
  ];

  const statBlocks = [
    {
      text: "Odometer Readings",
      value: dashboardData.readings_engagements_count,
    },
    {
      text: "Keywords Claimed",
      value: dashboardData.keyword_engagements_count,
    },
    {
      text: "Milestones Earned",
      value: dashboardData.milestones_achieved,
    },
    { text: "Dealerships Visited", value: dashboardData.locations_visited },
  ];

  return (
    <Box radius="md">
      <Box
        style={{
          // backgroundColor: `rgba(255, 255, 255, 0.975)`,
          // backgroundColor: `rgba(0, 0, 0, 0.88)`,
          backgroundColor: "#000",
          // backgroundColor: "transparent",
          borderRadius: "var(--mantine-radius-md) var(--mantine-radius-md) 0 0",
        }}
        pt={dashboardData.has_unverified_readings ? "0" : "lg"}
      >
        {dashboardData.has_unverified_readings && (
          <UnstyledButton
            style={{ width: "100%" }}
            onClick={() => setView(views.unverified_data)}
          >
            <StyledUnverifiedReadings>
              <Flex
                align="center"
                gap="xs"
                justify="center"
                style={{ width: "100%" }}
              >
                <IconExclamationCircle size={16} color="white" />
                <Text size="xs" fw={600} c="white">
                  There are items that need your attention
                </Text>
              </Flex>
            </StyledUnverifiedReadings>
          </UnstyledButton>
        )}
        <Header activeView={view} />
        <Box>
          {foundEffort && (
            <RevScore
              reqData={{
                user_id: user.id,
                campaign_effort_id: foundEffort.id,
              }}
              onRevScoreClick={() => setView(views.rev_score)}
              tiers={dashboardData.contest_tiers.results[0].tier_results}
              revScore={dashboardData.rev_score}
              color={appConfig.colors.base}
            />
          )}
        </Box>
      </Box>
      <StyledDashboardWrapper>
        <Box mb="lg" mt="lg">
          <RideChallengeKeywordForm
            effortId={effortId}
            onSuccess={() => {
              fetchData();
            }}
          />
        </Box>
        <Grid mb="lg">
          {navButtons.map((navButton, i) => (
            <Grid.Col span={navButton.span ? navButton.span : 6} key={i}>
              <UnstyledButton
                style={{ width: "100%" }}
                onClick={navButton.onClick}
              >
                <NavButton
                  color={navButton.has_alert ? appConfig.colors.base : ""}
                  hasAlert={navButton.has_alert}
                >
                  <Flex
                    align="center"
                    gap="xs"
                    // justify={navButton.centered ? "center" : "flex-start"}
                    justify="center"
                  >
                    {navButton.icon}
                    <Text size="sm" fw={600}>
                      {navButton.text}
                    </Text>
                  </Flex>
                </NavButton>
              </UnstyledButton>
            </Grid.Col>
          ))}
        </Grid>
        {/* <Box mt="lg" mb="lg">
        <MembershipButton
          color={appConfig.colors.base}
          onClick={() => setView(views.membership)}
          membership={dashboardData.hog_membership_data}
        />
      </Box> */}
        <Grid mb="lg">
          {statBlocks.map((statBlock, i) => (
            <Grid.Col span={6} key={i}>
              <StatBlock color={appConfig.colors.base}>
                <Text size="sm" align="center" className="label">
                  {statBlock.text}
                </Text>
                <Title order={2} c="dark" align="center" className="value">
                  {statBlock.value}
                </Title>
              </StatBlock>
            </Grid.Col>
          ))}
        </Grid>
        {location && (
          <Box mb="xl" mt="xl">
            <LocationInfo
              info={location}
              color={appConfig.colors.base}
              title="You're visiting"
              showGeo={location.present_at_location}
            />
          </Box>
        )}
        <Flex justify="center">
          <IframeModal
            src="https://www.mightyloud.com/rc-faq"
            buttonText="FAQ & Rules"
            buttonProps={{
              variant: "subtle",
              color: "gray",
              size: "xs",
            }}
          />
        </Flex>
      </StyledDashboardWrapper>
    </Box>
  );
}

const AnimatedWrapper = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0 }}
    // style={box}
    key="box"
  >
    {children}
  </motion.div>
);

const DashboardButton = ({ onClick }) => {
  return (
    <Flex justify="center">
      <Button
        onClick={onClick}
        // variant="light"
        c="dark"
        color="gray.1"
        size="xs"
        radius="lg"
      >
        back to dashboard
      </Button>
    </Flex>
  );
};

const StyledLogo = styled.img`
  width: 60%;
  display: block;
  margin: 0 auto;
`;

const NavButton = styled.div`
  /* background: ${(props) =>
    `var(--mantine-color-${props.color}-light-hover)`}; */
  /* border: ${(props) => `4px solid var(--mantine-color-${props.color}-7)`}; */
  border: ${(props) =>
    props.hasAlert
      ? `4px solid var(--mantine-color-${props.color}-7)`
      : `4px solid var(--mantine-color-dark-7)`};
  width: 100%;
  border-radius: 10px;
  /* color: #fff; */
  color: #000;
  padding: 10px;
  text-transform: uppercase;

  p {
    color: ${(props) =>
      props.hasAlert
        ? `var(--mantine-color-${props.color}-7)`
        : `var(--mantine-color-dark-7)`};
  }

  svg {
    stroke: ${(props) =>
      props.hasAlert
        ? `var(--mantine-color-${props.color}-7) !important`
        : `var(--mantine-color-dark-7)`};
  }
`;

const StatBlock = styled.div`
  background: var(--mantine-color-gray-2);
  border-radius: 10px;
  padding: 10px;

  .value {
    font-weight: 700;
  }

  .label {
    color: ${(props) => `var(--mantine-color-${props.color}-7)`};
    text-transform: uppercase;
    font-weight: 600;
  }
`;

const Header = ({ activeView }) => (
  <>
    <Flex justify="center">
      <StyledLogo src={logo} />
    </Flex>
    <RideChallengeAlerts activeView={activeView} />
  </>
);

const MembershipButton = ({ color, onClick, membership }) => {
  return (
    <StyledMembershipButton color={color}>
      <UnstyledButton onClick={onClick}>
        {membership ? (
          <Flex justify="center" align="center" gap="xs">
            <img src={hogLogo} alt="HOG Logo" />
            <div style={{ flexGrow: 1 }}>
              <Flex gap="sm" align="center">
                <Text size="lg" c="white" fw={600}>
                  {membership.ident}
                </Text>
                <Badge size="sm" color={membership.is_active ? "green" : "red"}>
                  {membership.is_active ? "Active" : "Inactive"}
                </Badge>
              </Flex>
              {membership.initial_activation_date ? (
                <Text c="dimmed">
                  Member since{" "}
                  {new Date(
                    membership.initial_activation_date
                  ).toLocaleDateString()}
                </Text>
              ) : (
                <Text c="dimmed">View membership details</Text>
              )}
            </div>
          </Flex>
        ) : (
          <>
            <Flex
              direction={{ base: "column", md: "row" }}
              justify="center"
              align="center"
              gap={{ base: "lg", md: "xs" }}
            >
              <img src={hogLogo} alt="HOG Logo" />
              <div style={{ flexGrow: 1 }}>
                <Text size="lg" c="white" fw={600}>
                  Join Harley Owners Group!
                </Text>
                <Text c="dimmed">
                  Harley Owners Group provides access to a world of exclusive
                  benefits to help you get the most out of your ownership
                  experience.
                </Text>
              </div>
            </Flex>
          </>
        )}
      </UnstyledButton>
    </StyledMembershipButton>
  );
};

const StyledMembershipButton = styled.div`
  /* background: ${(props) =>
    `var(--mantine-color-${props.color}-light-hover)`}; */
  background: var(--mantine-color-dark-8);
  border: ${(props) => `4px solid var(--mantine-color-${props.color}-7)`};
  border-radius: 10px;
  padding: 20px 10px;

  img {
    width: 125px;
    margin: 0 auto;
  }
`;

const StyledDashboardWrapper = styled.div`
  background: var(--mantine-color-gray-0);
  border: ${(props) => `4px solid var(--mantine-color-${props.color}-7)`};
  border-radius: 0 0 var(--mantine-radius-md) var(--mantine-radius-md);
  padding: 100px 10px 20px 10px;
  margin-top: -125px;

  @media (max-width: 768px) {
    padding: 80px 10px 20px 10px;
  }
`;

const LocationInfo = ({ info, color, title, showGeo }) => {
  return (
    <StyledLocationInfo>
      <Flex gap="xs" align="center">
        {info.logo_url ? (
          <StyledImage
            src={info.logo_url}
            alt={info.name}
            height={60}
            width={60}
          />
        ) : (
          <StyledPlaceholder height={60} width={60} />
        )}
        <div style={{ flexGrow: 1 }}>
          <Flex gap="xs" align="center">
            <Text
              c={`var(--mantine-color-${color}-7)`}
              size="xs"
              style={{ textTransform: "uppercase" }}
              fw={600}
            >
              {title}
            </Text>
            {showGeo && <IconLocationFilled size={10} />}
          </Flex>
          <Text fw={600} style={{ textTransform: "uppercase" }}>
            {info.name}
          </Text>
          {info.city && (
            <Text size="sm">
              {info.city}, {info.state_province}
            </Text>
          )}
        </div>
      </Flex>
    </StyledLocationInfo>
  );
};

const StyledLocationInfo = styled.div`
  width: 100%;
  padding: 0 10px;

  img {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    object-fit: cover;
    border-radius: 5px;
  }

  svg path {
    fill: var(--mantine-color-gray-4);
  }
`;

const StyledUnverifiedReadings = styled.div`
  width: 100%;
  background: var(--mantine-color-red-8);
  padding: 10px;
  border-radius: var(--mantine-radius-md) var(--mantine-radius-md) 0 0;
  margin-bottom: 15px;
`;
