import React, { useEffect, useState } from "react";
import axios from "axios";
import { shallow } from "zustand/shallow";
import {
  Box,
  Button,
  Badge,
  Drawer,
  Grid,
  Flex,
  Text,
  Modal,
  UnstyledButton,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import useAppStore from "./ride-challenge-store";
import styled from "styled-components";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import DealerVerification from "./DealerVerification";
import { LocationSelect } from "@components/Location";

export default function UserMilestones({
  reqData,
  setAppLoading,
  administrationData,
  loading,
}) {
  const [milestones, setMilestones] = useState([]);

  useEffect(() => {
    fetchMilestones();
  }, []);

  function fetchMilestones() {
    setAppLoading(true);

    axios
      .post(`/ride-challenge/milestones/`, reqData)
      .then(({ data }) => {
        setMilestones(data.response);
        setTimeout(() => {
          setAppLoading(false);
        }, 250);
      })
      .catch((err) => {
        setMilestones([]);
        setAppLoading(false);
      });
  }

  const milestoneGroups = [
    { title: "Mileage", milestones: milestones.filter((m) => m.variety === 1) },
    {
      title: "Odometer Readings",
      milestones: milestones.filter((m) => m.variety === 2),
    },
    {
      title: "Unique Dealer Visits",
      milestones: milestones.filter((m) => m.variety === 3),
    },
    {
      title: "Keyword Claims",
      milestones: milestones.filter((m) => m.variety === 4),
    },
  ].filter((f) => f.milestones.length > 0);

  return (
    <div>
      {loading === false && milestones.length === 0 && (
        <div
          style={{
            minHeight: "150px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Text align="center" fw={600} c="white">
            No milestones found
          </Text>
        </div>
      )}
      {milestoneGroups.map((group, i) => (
        <Box mb="lg" key={i}>
          <Text mb="xl" align="center" fw={600} c="white">
            {group.title}
          </Text>
          <Grid gutter="xl">
            {group.milestones.map((milestone) => (
              <Grid.Col span={{ base: 4, md: 4 }} key={milestone.id}>
                <MilestoneItem
                  info={milestone}
                  fetchData={fetchMilestones}
                  administrationData={administrationData}
                />
              </Grid.Col>
            ))}
          </Grid>
        </Box>
      ))}
      {/* {milestones.map((milestone) => (
        <Grid.Col span={{ base: 4, md: 4 }} key={milestone.id}>
          <MilestoneItem
            info={milestone}
            fetchData={fetchMilestones}
            administrationData={administrationData}
          />
        </Grid.Col>
      ))} */}
    </div>
  );
}

const StyledImage = styled.img`
  width: ${(props) => (props.width ? `${props.width}px` : `75px`)};
  height: ${(props) => (props.height ? `${props.height}px` : `75px`)};
  object-fit: cover;
  border-radius: 5px;
  opacity: ${(props) => (props.earned ? 1 : 0.08)};
`;

const StyledPlaceholder = styled.div`
  width: ${(props) => (props.width ? `${props.width}px` : `75px`)};
  height: ${(props) => (props.height ? `${props.height}px` : `75px`)};
  background: var(--mantine-color-gray-4);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MilestoneItem = ({ info, fetchData, administrationData }) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <UnstyledButton onClick={open}>
          <Flex justify="center" mb="8px">
            {info.filename_url ? (
              <StyledImage
                earned={info.milestone_award_id ? true : false}
                src={info.filename_url}
              />
            ) : (
              <StyledPlaceholder />
            )}
          </Flex>
          <Text align="center" c="white" fw={600} size="xs">
            {info.title}{" "}
          </Text>
          {info.offer_pickup && (
            <Flex justify="center" mt="xs">
              <Badge size="xs">Claim Now</Badge>
            </Flex>
          )}
        </UnstyledButton>
      </div>
      <Drawer
        className="user-milestone-drawer"
        position="bottom"
        opened={opened}
        onClose={close}
      >
        <Flex gap="md" align="flex-start">
          {info.filename_url && <StyledImage earned src={info.filename_url} />}
          <div style={{ flexGrow: 1 }}>
            <Box mb="lg">
              <Text c="white" fw={600}>
                {info.title}
              </Text>
              <Text c="white" size="sm">
                {info.description}
              </Text>
            </Box>
            {info.earned_at_location_name && (
              <Text c="dimmed" size="xs">
                Earned at {info.earned_at_location_name}
              </Text>
            )}
            {info.awarded_at_location_name && (
              <Text c="dimmed" size="xs">
                Awarded at {info.awarded_at_location_name}
                {info.received_from && (
                  <> and received from {info.received_from}</>
                )}
              </Text>
            )}
            {info.earned_at && (
              <Text c="dimmed" size="xs">
                {new Date(info.earned_at).toLocaleString()}
              </Text>
            )}
            {info.offer_pickup && (
              <Box mt="lg">
                <MilestoneClaim
                  administrationData={administrationData}
                  onSuccess={() => {
                    fetchData();
                    close();
                  }}
                  reqData={{
                    milestone_award_id: info.milestone_award_id,
                  }}
                />
              </Box>
            )}
          </div>
        </Flex>
      </Drawer>
    </div>
  );
};

const MilestoneClaim = ({ reqData, onSuccess, administrationData }) => {
  const [showScanner, setShowScanner] = useState(false);
  const [dealerCodeData, setDealerCodeData] = useState(null);
  const [awarding, setAwarding] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const managerInfo = useSelector((state) => state.manager);
  const adminInfo = useSelector((state) => state.admin);

  const { coords, effortId, location, user } = useAppStore(
    (state) => ({
      coords: state.coords,
      effortId: state.effortId,
      location: state.location,
      user: state.user,
    }),
    shallow
  );

  function onAwardClick() {
    const req =
      managerInfo || adminInfo
        ? {
            ...administrationData,
            ...reqData,
            manager_id: managerInfo?.manager_id,
            admin_id: adminInfo?.admin_id,
          }
        : {
            ...reqData,
            campaign_effort_id: effortId,
            location_id: location?.id,
            user_id: user.id,
            remote_code_id: dealerCodeData.id,
          };

    if (managerInfo && managerInfo.location_id)
      req.location_id = managerInfo.location_id;

    if (managerInfo && managerInfo.organization_id)
      req.organization_id = managerInfo.organization_id;

    if (selectedLocationId) req.location_id = selectedLocationId;

    setAwarding(true);

    axios
      .post(`/ride-challenge/milestone-pickup/`, req)
      .then(() => {
        setAwarding(false);
        setDealerCodeData(null);
        setShowScanner(false);
        onSuccess();
      })
      .catch((err) => {
        setAwarding(false);
        toast.error(err);
      });
  }

  const submitDisabled = adminInfo
    ? !selectedLocationId
    : managerInfo && managerInfo.organization_id
    ? !selectedLocationId
    : false;

  if (dealerCodeData) {
    return (
      <div>
        {(adminInfo || managerInfo?.organization_id) && (
          <Box mb="sm">
            <StyledForm>
              <LocationSelect
                reqData={{
                  campaign_effort_id: administrationData
                    ? administrationData.campaign_effort_id
                    : effortId,
                }}
                value={selectedLocationId}
                onChange={(e) => setSelectedLocationId(e)}
              />
            </StyledForm>
          </Box>
        )}
        <Button
          disabled={submitDisabled}
          loading={awarding}
          onClick={() => onAwardClick()}
        >
          I've awarded the milestone
        </Button>
        <Flex mt="lg" justify="center">
          <Button
            color="gray"
            variant="subtle"
            onClick={() => {
              setShowScanner(false);
              setDealerCodeData(null);
            }}
          >
            Exit
          </Button>
        </Flex>
      </div>
    );
  }

  if (showScanner) {
    return (
      <>
        {/* <div className="user-milestone-drawer">
          <DealerVerification
            codeVerificationData={{
              ...coords,
              user_location_id: user?.user_location_id,
              campaign_effort_id: effortId,
              location_id: location?.id,
              user_id: user?.id,
            }}
            setDealerCodeData={(e) => setDealerCodeData(e)}
          />
          <Flex mt="lg" justify="center">
            <Button
              color="gray"
              variant="subtle"
              onClick={() => setShowScanner(false)}
            >
              Back
            </Button>
          </Flex>
        </div> */}
        <Modal
          className="user-milestone-claim-modal"
          onClose={() => setShowScanner(false)}
          opened
        >
          <DealerVerification
            codeVerificationData={{
              ...coords,
              user_location_id: user?.user_location_id,
              campaign_effort_id: effortId,
              location_id: location?.id,
              user_id: user?.id,
            }}
            setDealerCodeData={(e) => setDealerCodeData(e)}
          />
          <Flex mt="lg" justify="center">
            <Button
              color="gray"
              variant="subtle"
              onClick={() => setShowScanner(false)}
            >
              Back
            </Button>
          </Flex>
        </Modal>
      </>
    );
  }

  return (
    <div>
      <Button
        onClick={() => {
          if (!managerInfo && !adminInfo) {
            setShowScanner(true);
          } else {
            setDealerCodeData({
              id: 123,
            });
          }
        }}
        size="xs"
      >
        Claim Reward
      </Button>
    </div>
  );
};

const StyledForm = styled.div`
  label {
    color: var(--mantine-color-gray-0);
  }
`;
