import React, { act, useEffect, useState } from "react";
import axios from "axios";
import { Badge, Flex, Button, Box, Text, UnstyledButton } from "@mantine/core";
import toast from "react-hot-toast";
import styled from "styled-components";

import DealerVerification from "./DealerVerification";
import { UnverifiedItem, OdometerForm } from "./VehicleVerificationView";
import OdometerValueMismatch from "./OdometerValueMismatch";
import DealerWarnings from "./DealerWarnings";
import DealerMode from "./DealerMode";

export default function UnverifiedItems({
  items,
  additionalReqData,
  fetchData,
  verificationCode,
  administrationData = null,
  codeVerificationData,
  children,
}) {
  const [activeItem, setActiveItem] = useState(null);
  const [dealerCodeData, setDealerCodeData] = useState(null);
  const [value, setValue] = useState("");
  const [dealerSubmitting, setDealerSubmitting] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState("");

  const locationNeeded =
    administrationData &&
    (administrationData.admin_id || administrationData.organization_id);

  useEffect(() => {
    setDealerCodeData(null);
    setValue("");
    setDealerSubmitting(false);

    if (verificationCode) {
      setDealerCodeData(verificationCode);
    }
  }, [activeItem]);

  function onDealerSubmit() {
    setDealerSubmitting(true);

    const req = administrationData
      ? {
          ...administrationData,
          campaign_effort_id: activeItem.campaign_effort_id,
          odometer_value: value,
          ride_challenge_data_point_id: activeItem.id,
          user_vehicle_id: activeItem.user_vehicle_id,
        }
      : {
          ...additionalReqData,
          verifier_remote_code_id: dealerCodeData.id,
          odometer_value: value,
          ride_challenge_data_point_id: activeItem.id,
          user_vehicle_id: activeItem.user_vehicle_id,
        };

    if (!activeItem.user_vehicle_id) {
      req.vin = activeItem.vin;
      req.checksum_results = activeItem.checksum_results;
      req.nhtsa_results = activeItem.nhtsa_results;
      req.year = activeItem.year;
      req.make = activeItem.make;
      req.model = activeItem.model;
      req.series = activeItem.series;
    }

    if (selectedLocationId) {
      req.location_id = selectedLocationId;
    }

    axios
      .post(`/ride-challenge/verify-reading/`, req)
      .then(() => {
        toast.success("Verified!");
        setDealerSubmitting(false);
        // fetchUser();
        setActiveItem(null);
        setSelectedLocationId("");
        fetchData();
      })
      .then(() => fetchData())
      .catch((err) => {
        toast.error(err);
        setDealerSubmitting(false);
      });
  }

  function onDeleteClick(itemId) {
    axios
      .post(`/ride-challenge/inactivate-data-point/`, {
        ride_challenge_data_point_id: itemId,
      })
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  if (items.length === 0) {
    return (
      <Text c="white" align="center">
        Nothing to do here at the moment.
      </Text>
    );
  }

  if (activeItem) {
    return (
      <div>
        {/* <Box>
          <Text c="white" fw={600} size="xl" align="center" mb="xl">
            {activeItem.vin}
          </Text>
        </Box> */}
        {dealerCodeData ? (
          <DealerMode border={false} dealerCodeData={dealerCodeData}>
            {!activeItem.user_vehicle_id && (
              <UnverifiedItem
                info={activeItem}
                additionalReqData={{
                  ...additionalReqData,
                  verifier_remote_code_id: dealerCodeData.id,
                }}
                fetchUser={() => {
                  setActiveItem(null);
                  fetchData();
                }}
              />
            )}
            {activeItem.user_vehicle_id && (
              <div>
                <DealerWarnings
                  reqData={{
                    user_id: activeItem.user_id,
                    campaign_effort_id: activeItem.campaign_effort_id,
                  }}
                />
                <Flex justify="center" mt="lg">
                  <OdometerForm value={value} onChange={(e) => setValue(e)} />
                </Flex>
                {activeItem.odometer_value && (
                  <OdometerValueMismatch
                    originalValue={activeItem.odometer_value}
                    newValue={value}
                  />
                )}
                <Flex justify="center" mt="lg">
                  <Button
                    onClick={onDealerSubmit}
                    disabled={!value}
                    loading={dealerSubmitting}
                  >
                    Submit Reading
                  </Button>
                </Flex>
              </div>
            )}
          </DealerMode>
        ) : (
          <DealerVerification
            codeVerificationData={codeVerificationData}
            setDealerCodeData={(e) => setDealerCodeData(e)}
          />
        )}
        <Flex justify="center" mt="lg" gap="xs">
          <Button
            c="dark"
            color="gray.1"
            size="xs"
            radius="lg"
            onClick={() => setActiveItem(null)}
          >
            back to unverified items
          </Button>
          {children && <>{children}</>}
        </Flex>
      </div>
    );
  }

  return (
    <div>
      {items.map((item, i) => {
        const source = item.user_vehicle || item;

        return (
          <div key={item.id}>
            <Box mb="lg">
              <Badge radius="sm" mb="xs" size="xs" variant="light">
                {item.vin ? "VIN Verification" : "Reading Verification"}
              </Badge>
              <Text c="white" fw={600}>
                {source.year} {source.make} {source.model}{" "}
                {source.series && `${source.series}`}
              </Text>
              <Text c="dimmed">{item.odometer_value} miles</Text>
              {source.vin && (
                <Text c="dimmed">
                  <span style={{ textTransform: "uppercase" }}>
                    {source.vin}
                  </span>
                </Text>
              )}
              {/* {locationNeeded && (
                <Box>
                  <StyledForm>
                    <LocationSelect
                      label="Location to verify at"
                      reqData={{
                        campaign_effort_id: item.campaign_effort_id,
                      }}
                      value={selectedLocationId}
                      onChange={(e) => setSelectedLocationId(e)}
                    />
                  </StyledForm>
                </Box>
              )} */}
              {!administrationData && (
                <Flex gap="xs" align="center" mt="sm">
                  <Button
                    size="xs"
                    onClick={() => setActiveItem(item)}
                    radius="lg"
                    disabled={
                      locationNeeded && !selectedLocationId ? true : false
                    }
                  >
                    Verify Now
                  </Button>
                  <DeleteButton onConfirmClick={() => onDeleteClick(item.id)} />
                </Flex>
              )}
            </Box>
          </div>
        );
      })}
      {children && <Box mt="xl">{children}</Box>}
    </div>
  );
}

const DeleteButton = ({ onConfirmClick }) => {
  const [isConfirming, setIsConfirming] = useState(false);

  if (isConfirming) {
    return (
      <Flex justify="center" gap="xs">
        <Button
          radius="lg"
          variant="outline"
          size="xs"
          color="gray"
          onClick={() => setIsConfirming(false)}
        >
          Cancel
        </Button>
        <Button color="red" radius="lg" size="xs" onClick={onConfirmClick}>
          Confirm
        </Button>
      </Flex>
    );
  }

  return (
    <Button
      size="xs"
      onClick={() => setIsConfirming(true)}
      color="gray"
      radius="lg"
      variant="outline"
    >
      Delete
    </Button>
  );
};
